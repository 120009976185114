import React, { useEffect, useRef, useState } from "react";
import Card from "../../components/Card";
import Layout from "../../components/Layout";

export default function BadanUsaha(props) {
  const [selected, setSelected] = useState([]);
  const [textPaketWA, setTextPaketWA] = useState("");

  const tex = selected.map((item, index) => {
    return `${index + 1}. ${item}%0a`;
  });

  const text1 = `Halo, Saya ingin memesan paket ${
    textPaketWA?.includes("&")
      ? textPaketWA.replace("&amp;", "Dan")
      : textPaketWA
  }.%0a%0aSaya Memiliki Berkas Sebagai Berikut :%0a${tex.join("")}`;

  const refSection = {
    perseroanTerbatas: useRef(null),
    startup: useRef(null),
    cv: useRef(null),
    yayasan: useRef(null),
    firma: useRef(null),
    Konstruksi: useRef(null),
  };

  const dataServices = {
    id: "asdasd13123",
    title: "Pendirian Badan Usaha",
    services: [
      {
        id: "pt",
        title: "Pendirian PT (Perseroan Terbatas)",
        ref: refSection.perseroanTerbatas,
        items: {
          id: "asdasd123123",
          packages: [
            {
              id: "asdasd13123",
              title: "Paket Pendirian PT",
              obtaineds: [
                {
                  title: "Akta Pendirian",
                  isAvailable: true,
                },
                {
                  title: "SK MENKUMHAM",
                  isAvailable: true,
                },
                {
                  title: "NPWP Perusahaan",
                  isAvailable: true,
                },
                {
                  title: "NIB",
                  isAvailable: true,
                },
                {
                  title: "SKDP (Surat Izin Domisili)",
                  isAvailable: false,
                },
                {
                  title: "BPJS Ketenagakerjaan",
                  isAvailable: false,
                },
                {
                  title: "SIUP",
                  isAvailable: false,
                },
                {
                  title: "Virtual Office Selama 1 Tahun",
                  isAvailable: false,
                },
              ],
              price: 5500000,
              isTerlaris: true,
            },
            {
              id: "asdasd13123",
              title: "Paket Lengkap PT",
              obtaineds: [
                {
                  title: "Akta Pendirian",
                  isAvailable: true,
                },
                {
                  title: "SK MENKUMHAM",
                  isAvailable: true,
                },
                {
                  title: "NPWP Perusahaan",
                  isAvailable: true,
                },
                {
                  title: "NIB",
                  isAvailable: true,
                },
                {
                  title: "SKDP (Surat Izin Domisili)",
                  isAvailable: true,
                },
                {
                  title: "BPJS Ketenagakerjaan",
                  isAvailable: true,
                },
                {
                  title: "SIUP",
                  isAvailable: true,
                },
                {
                  title: "Virtual Office Selama 1 Tahun",
                  isAvailable: false,
                },
              ],
              price: 9000000,
              isTerlaris: false,
            },
            {
              id: "asdasd13123",
              title: "Paket Lengkap PT & Virtual Office",
              obtaineds: [
                {
                  title: "Akta Pendirian",
                  isAvailable: true,
                },
                {
                  title: "SK MENKUMHAM",
                  isAvailable: true,
                },
                {
                  title: "NPWP Perusahaan",
                  isAvailable: true,
                },
                {
                  title: "NIB",
                  isAvailable: true,
                },
                {
                  title: "SKDP (Surat Izin Domisili)",
                  isAvailable: true,
                },
                {
                  title: "BPJS Ketenagakerjaan",
                  isAvailable: true,
                },
                {
                  title: "SIUP",
                  isAvailable: true,
                },
                {
                  title: "Virtual Office Selama 1 Tahun",
                  isAvailable: true,
                },
              ],
              price: 11000000,
              isTerlaris: false,
            },
          ],
        },
        terms: [
          "Nama Perusahaan (Siapkan 1 Nama Cadangan)",
          "KTP dan NPWP Para Pendiri Minimal 2 Orang",
          "Sub Bidang Usaha",
          "Modal Awal",
          "Pembagian Persenan Saham",
          "Domisili Perusahaan",
          "Nomor Telpon dan Email Para Pendiri",
          "Kartu Keluarga",
          "Fotocopy Sertifikat Rumah/Tempat Usaha Apabila Disewa Maka Akan di Ganti Dengan Surat Keluasan Atau Keterangan Sewa Rumah",
          "IMB dan PBB Terakhir",
          "Surat Pengantar RT/RW untuk Surat Keterangan Domisili Perusahaan",
          "Email Perusahaan",
          "Stempel Perusahaan",
        ],
      },
      {
        id: "cv",
        title: "Pendirian CV",
        ref: refSection.cv,
        items: {
          id: "asdasdas2312",
          packages: [
            {
              id: "asdasd13123",
              title: "Paket Pendirian CV",
              obtaineds: [
                {
                  title: "Akta Pendirian",
                  isAvailable: true,
                },
                {
                  title: "SK MENKUMHAM",
                  isAvailable: true,
                },
                {
                  title: "NPWP Perusahaan",
                  isAvailable: true,
                },
                {
                  title: "NIB",
                  isAvailable: false,
                },
                {
                  title: "SKDP (Surat Izin Domisili)",
                  isAvailable: false,
                },
                {
                  title: "BPJS Ketenagakerjaan",
                  isAvailable: false,
                },
                {
                  title: "SIUP (Surat Izin Usaha)",
                  isAvailable: false,
                },
                {
                  title: "Virtual Office Selama 1 Tahun",
                  isAvailable: false,
                },
              ],
              price: 2500000,
              isTerlaris: false,
            },
            {
              id: "asdasd13123",
              title: "Paket Lengkap CV",
              obtaineds: [
                {
                  title: "Akta Pendirian",
                  isAvailable: true,
                },
                {
                  title: "SK MENKUMHAM",
                  isAvailable: true,
                },
                {
                  title: "NPWP Perusahaan",
                  isAvailable: true,
                },
                {
                  title: "NIB",
                  isAvailable: true,
                },
                {
                  title: "SKDP (Surat Izin Domisili)",
                  isAvailable: true,
                },
                {
                  title: "BPJS Ketenagakerjaan",
                  isAvailable: true,
                },
                {
                  title: "SIUP (Surat Izin Usaha)",
                  isAvailable: true,
                },
                {
                  title: "Virtual Office Selama 1 Tahun",
                  isAvailable: false,
                },
              ],
              price: 5000000,
              isTerlaris: true,
            },
            {
              id: "asdasd13123",
              title: "Paket Lengkap CV & Virtual Office",
              obtaineds: [
                {
                  title: "Akta Pendirian",
                  isAvailable: true,
                },
                {
                  title: "SK MENKUMHAM",
                  isAvailable: true,
                },
                {
                  title: "NPWP",
                  isAvailable: true,
                },
                {
                  title: "NIB",
                  isAvailable: true,
                },
                {
                  title: "SKDP (Surat Izin Domisili)",
                  isAvailable: true,
                },
                {
                  title: "BPJS Ketenagakerjaan",
                  isAvailable: true,
                },
                {
                  title: "SIUP (Surat Izin Usaha)",
                  isAvailable: true,
                },
                {
                  title: "Virtual Office Selama 1 Tahun",
                  isAvailable: true,
                },
              ],
              price: 7500000,
              isTerlaris: false,
            },
          ],
        },
        terms: [
          "Nama Perusahaan (Siapkan 1 Nama Cadangan)",
          "KTP dan NPWP Para Pendiri Minimal 2 Orang",
          "Sub Bidang Usaha",
          "Modal Awal",
          "Pembagian Persenan Saham",
          "Domisili Perusahaan",
          "Nomor Telpon dan Email Para Pendiri",
          "Kartu Keluarga",
          "Fotocopy Sertifikat Rumah/Tempat Usaha Apabila Disewa Maka Akan di Ganti Dengan Surat Keluasan Atau Keterangan Sewa Rumah",
          "IMB dan PBB Terakhir",
          "Surat Pengantar RT/RW untuk Surat Keterangan Domisili Perusahaan",
          "Email Perusahaan",
          "Stempel Perusahaan",
        ],
      },
      {
        id: "startup",
        title: "Pendirian Startup",
        ref: refSection.startup,
        items: {
          id: "sadsad213123",
          packages: [
            {
              id: "asdasd13123",
              title: "Paket Pendirian Startup PT",
              obtaineds: [
                {
                  title: "Akta Pendirian",
                  isAvailable: true,
                },
                {
                  title: "SK MENKUMHAM",
                  isAvailable: true,
                },
                {
                  title: "NPWP",
                  isAvailable: true,
                },
                {
                  title: "NIB",
                  isAvailable: true,
                },
                {
                  title: "SKDP (Surat Izin Domisili)",
                  isAvailable: false,
                },
                {
                  title: "BPJS Ketenagakerjaan",
                  isAvailable: false,
                },
                {
                  title: "SIUP (Surat Izin Usaha)",
                  isAvailable: false,
                },
                {
                  title: "Virtual Office Selama 1 Tahun",
                  isAvailable: false,
                },
              ],
              price: 5500000,
              isTerlaris: false,
            },
            {
              id: "asdasd13123",
              title: "Paket Lengkap Startup PT",
              obtaineds: [
                {
                  title: "Akta Pendirian",
                  isAvailable: true,
                },
                {
                  title: "SK MENKUMHAM",
                  isAvailable: true,
                },
                {
                  title: "NPWP",
                  isAvailable: true,
                },
                {
                  title: "NIB",
                  isAvailable: true,
                },
                {
                  title: "SKDP (Surat Izin Domisili)",
                  isAvailable: true,
                },
                {
                  title: "BPJS Ketenagakerjaan",
                  isAvailable: true,
                },
                {
                  title: "SIUP (Surat Izin Usaha)",
                  isAvailable: true,
                },
                {
                  title: "Virtual Office Selama 1 Tahun",
                  isAvailable: true,
                },
              ],
              price: 10500000,
              isTerlaris: true,
            },
          ],
        },
        terms: [
          "Nama Perusahaan (Siapkan 1 Nama Cadangan)",
          "KTP dan NPWP Para Pendiri Minimal 2 Orang",
          "Sub Bidang Usaha",
          "Modal Awal",
          "Pembagian Persenan Saham",
          "Domisili Perusahaan",
          "Nomor Telpon dan Email Para Pendiri",
          "Kartu Keluarga",
          "Fotocopy Sertifikat Rumah/Tempat Usaha Apabila Disewa Maka Akan di Ganti Dengan Surat Keluasan Atau Keterangan Sewa Rumah",
          "IMB dan PBB Terakhir",
          "Surat Pengantar RT/RW untuk Surat Keterangan Domisili Perusahaan",
          "Email Perusahaan",
          "Stempel Perusahaan",
        ],
      },
      {
        id: "yayasan",
        title: "Pendirian Yayasan",
        ref: refSection.yayasan,
        items: {
          id: "asdasd213123",
          packages: [
            {
              id: "asdasd13123",
              title: "Paket Pendirian Yayasan",
              obtaineds: [
                {
                  title: "Akta Pendirian",
                  isAvailable: true,
                },
                {
                  title: "SK MENKUMHAM",
                  isAvailable: true,
                },
                {
                  title: "NPWP Yayasan",
                  isAvailable: true,
                },
                {
                  title: "NIB",
                  isAvailable: false,
                },
                {
                  title: "SKDP (Surat Izin Domisili)",
                  isAvailable: false,
                },
                {
                  title: "Izin Operasional",
                  isAvailable: false,
                },
              ],
              price: 4000000,
              isTerlaris: true,
            },
            {
              id: "asdasd13123",
              title: "Paket Lengkap Yayasan",
              obtaineds: [
                {
                  title: "Akta Pendirian",
                  isAvailable: true,
                },
                {
                  title: "SK MENKUMHAM",
                  isAvailable: true,
                },
                {
                  title: "NPWP Yayasan",
                  isAvailable: true,
                },
                {
                  title: "NIB",
                  isAvailable: true,
                },
                {
                  title: "SKDP (Surat Izin Domisili)",
                  isAvailable: true,
                },
                {
                  title: "Izin Operasional",
                  isAvailable: true,
                },
              ],
              price: 7500000,
              isTerlaris: false,
            },
          ],
        },
        terms: [
          "Nama Yayasan (Siapkan Satu Nama Cadangan)",
          "KTP dan NPWP Para Pengurus Yayasan",
          "Domisili Yayasan",
          "Bidang Kegiatan Yayasan",
          "Nomor Telpon dan Email Para Pengurus Yayasan",
          "Fotocopy Sertifikat Rumah/Tempat Yayasan Apabila Disewa Maka Akan Diganti Dengan Surat Keluasan Atau Keterangan Sewa Rumah",
          "IMB dan PBB Terakhir",
        ],
      },
      {
        id: "firma",
        title: "Firma",
        ref: refSection.firma,
        items: {
          id: "sadasdq312",
          packages: [
            {
              id: "asdasd13123",
              title: "Paket Pendirian Firma",
              obtaineds: [
                {
                  title: "Akta Pendirian",
                  isAvailable: true,
                },
                {
                  title: "SK MENKUMHAM",
                  isAvailable: true,
                },
                {
                  title: "NPWP",
                  isAvailable: true,
                },
                {
                  title: "NIB",
                  isAvailable: true,
                },
                {
                  title: "Izin Usaha",
                  isAvailable: false,
                },
              ],
              price: 7000000,
              isTerlaris: true,
            },
          ],
          terms: [
            "Pendirian PT (Perseroan Terbatas)",
            "Pendirian PT (Perseroan Terbatas)",
            "Pendirian PT (Perseroan Terbatas)",
            "Pendirian PT (Perseroan Terbatas)",
            "Pendirian PT (Perseroan Terbatas)",
            "Pendirian PT (Perseroan Terbatas)",
            "Pendirian PT (Perseroan Terbatas)",
            "Pendirian PT (Perseroan Terbatas)",
          ],
        },
        terms: [
          "Data Nama para pendiri Firma sesuai KTP",
          "Nama perusahaan",
          "Tempat dan kedudukan perusahaan (kota/kabupaten)",
          "Maksud dan tujuan perusahaan yaitu bidang usaha dan lingkup kegiatan usaha",
          "Nama susunan pengurus Firma (Direktur)",
          "NPWP Para Pendiri",
        ],
      },
      {
        id: "konstruksi",
        title: "Pendirian Konstruksi / Konsultan",
        ref: refSection.Konstruksi,
        items: {
          id: "sadqw3123",
          packages: [
            {
              id: "asdasd13123",
              title: "Paket Kecil Konstruksi / Konsultan CV",
              obtaineds: [
                {
                  title: "Akta Pendirian",
                  isAvailable: true,
                },
                {
                  title: "SK MENKUMHAM",
                  isAvailable: true,
                },
                {
                  title: "NPWP Perusahaan",
                  isAvailable: true,
                },
                {
                  title: "NIB",
                  isAvailable: true,
                },
                {
                  title: "SKDP (Surat Izin Domisili)",
                  isAvailable: true,
                },
                {
                  title: "BPJS Ketenagakerjaan",
                  isAvailable: true,
                },
                {
                  title: "SIUJK (Perizinan Online)",
                  isAvailable: true,
                },
                {
                  title: "Izin Usaha Jasa Konstruksi",
                  isAvailable: false,
                },
                {
                  title: "SBU",
                  isAvailable: false,
                },
                {
                  title: "SKA/SKT",
                  isAvailable: false,
                },
                {
                  title: "Virtual Office 1 Tahun",
                  isAvailable: false,
                },
              ],
              price: 5000000,
              isTerlaris: true,
            },
            {
              id: "asdasd13123",
              title: "Paket Lengkap Konstruksi / Konsultan CV",
              obtaineds: [
                {
                  title: "Akta Pendirian",
                  isAvailable: true,
                },
                {
                  title: "SK MENKUMHAM",
                  isAvailable: true,
                },
                {
                  title: "NPWP Perusahaan",
                  isAvailable: true,
                },
                {
                  title: "NIB",
                  isAvailable: true,
                },
                {
                  title: "SKDP (Surat Izin Domisili)",
                  isAvailable: true,
                },
                {
                  title: "BPJS Ketenagakerjaan",
                  isAvailable: true,
                },
                {
                  title: "Izin Usaha Jasa Konstruksi",
                  isAvailable: true,
                },
                {
                  title: "SBU",
                  isAvailable: true,
                },
                {
                  title: "SKA/SKT",
                  isAvailable: true,
                },
                {
                  title: "Virtual Office 1 Tahun",
                  isAvailable: true,
                },
              ],
              price: 16000000,
              isTerlaris: false,
            },
            {
              id: "asdasd13123",
              title: "Paket Lengkap Konstruksi / Konsultan PT",
              obtaineds: [
                {
                  title: "Akta Pendirian",
                  isAvailable: true,
                },
                {
                  title: "SK MENKUMHAM",
                  isAvailable: true,
                },
                {
                  title: "NPWP Perusahaan",
                  isAvailable: true,
                },
                {
                  title: "NIB",
                  isAvailable: true,
                },
                {
                  title: "SKDP (Surat Izin Domisili)",
                  isAvailable: true,
                },
                {
                  title: "BPJS Ketenagakerjaan",
                  isAvailable: true,
                },
                {
                  title: "Izin Usaha Jasa Konstruksi",
                  isAvailable: true,
                },
                {
                  title: "SBU",
                  isAvailable: true,
                },
                {
                  title: "SKA/SKT",
                  isAvailable: true,
                },
                {
                  title: "Virtual Office 1 Tahun",
                  isAvailable: true,
                },
              ],
              price: 20000000,
              isTerlaris: false,
            },
          ],
          terms: [
            "Pendirian PT (Perseroan Terbatas)",
            "Pendirian PT (Perseroan Terbatas)",
            "Pendirian PT (Perseroan Terbatas)",
            "Pendirian PT (Perseroan Terbatas)",
            "Pendirian PT (Perseroan Terbatas)",
            "Pendirian PT (Perseroan Terbatas)",
            "Pendirian PT (Perseroan Terbatas)",
            "Pendirian PT (Perseroan Terbatas)",
          ],
        },
      },
    ],
  };

  const handleCheckboxChange = (event) => {
    let newArray = [...selected, event.target.value];
    if (selected.includes(event.target.value)) {
      newArray = newArray.filter((i) => i !== event.target.value);
    }
    setSelected(newArray);
  };

  const handleClik = (ref, id) => {
    const parentList = document.querySelector("#parentList");
    const dataContent = document.getElementById(id).offsetTop;

    parentList.scrollTo({
      left: ref.current.offsetLeft - 20,
      behavior: "smooth",
    });

    window.scrollTo({
      top: dataContent - 180,
      // behavior: "smooth",
    });
  };

  const [modal, setModal] = useState(false);
  const [temsIndex, setTemsIndex] = useState(0);

  const showModal = (modal, index, paketWA) => {
    if (modal === true) {
      setSelected([]);
    }
    setModal(modal);
    setTemsIndex(index);
    setTextPaketWA(paketWA);
    console.log(paketWA);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  });

  return (
    <Layout {...props}>
      <section className="bg-black w-full lg:hidden fixed z-10">
        <div className="container mx-auto pt-20 overflow-hidden bg-black">
          <div className="overflow-x-scroll py-4 mx-4" id="parentList">
            <ul className="text-white flex md:justify-center whitespace-nowrap">
              <li
                className="bg-blue-400 mr-4 px-5 py-1 flex rounded cursor-pointer"
                ref={refSection.perseroanTerbatas}
                onClick={() =>
                  handleClik(
                    refSection.perseroanTerbatas,
                    dataServices.services[0].id
                  )
                }
              >
                PT
              </li>

              <li
                className="bg-blue-400 mr-4 px-5 py-1 flex rounded cursor-pointer"
                ref={refSection.cv}
                onClick={() =>
                  handleClik(refSection.cv, dataServices.services[1].id)
                }
              >
                CV
              </li>

              <li
                className="bg-blue-400 mr-4 px-5 py-1 flex rounded cursor-pointer"
                ref={refSection.startup}
                onClick={() =>
                  handleClik(refSection.startup, dataServices.services[2].id)
                }
              >
                Startup
              </li>

              <li
                className="bg-blue-400 mr-4 px-5 py-1 flex rounded cursor-pointer"
                ref={refSection.yayasan}
                onClick={() =>
                  handleClik(refSection.yayasan, dataServices.services[3].id)
                }
              >
                Yayasan
              </li>

              <li
                className="bg-blue-400 mr-4 px-5 py-1 flex rounded cursor-pointer"
                ref={refSection.firma}
                onClick={() =>
                  handleClik(refSection.firma, dataServices.services[4].id)
                }
              >
                Firma
              </li>

              <li
                className="bg-blue-400 mr-4 px-5 py-1 flex rounded cursor-pointer"
                ref={refSection.Konstruksi}
                onClick={() =>
                  handleClik(refSection.Konstruksi, dataServices.services[5].id)
                }
              >
                Konstruksi
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="h-screen90 bg-black">
        <div className="container mx-auto pt-44">
          <div className="w-full lg:px-24 -mt-8">
            <div className="flex flex-wrap -lg:mx-4 justify-center">
              <div className="w-full sm:w-1/2 md:w-1/2 px-4">
                <h1 className="w-full text-center text-white text-2xl font-semibold xl:text-4xl">
                  Silahkan Pilih Layanan Kami
                </h1>
                {/* <p className="text-gray-400 mt-8 text-lg text-center">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa
                  non excepturi expedita, commodi sit doloremque!
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="text-white -mt-60 mb-44" id="pendirian-badan-usaha">
        {dataServices.services.map((service, i) => {
          return (
            <div
              className={`container mx-auto ${
                i > 0 ? "text-black  mt-20" : ""
              }`}
              key={i}
              id={service.id}
              ref={refSection.ref}
            >
              <h3 className="text-xl px-4 text-center mb-8 font-semibold xl:text-2xl uppercase">
                {service.title}
              </h3>

              <div className="w-full lg:px-24 -mt-8">
                <div className="flex flex-wrap lg:-mx-4 justify-center">
                  {service.items?.packages.map((paket, i) => {
                    return (
                      <Card
                        key={i}
                        isLayanan
                        dataLayanan={paket}
                        // terms={service.items?.terms}
                        btnFunc={(index, paketWA) =>
                          showModal(!modal, index, paketWA)
                        }
                        modalTitle={service.title}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}

        {modal && (
          <div className="fixed inset-0 z-30 flex justify-center items-center bg-black bg-opacity-40 animate-modalAnim">
            <div className="w-full h-5/6  mx-4 py-4 px-8 bg-white text-black md:w-4/5 lg:w-1/2 relative animate-modalForm rounded-lg overflow-y-scroll scroll ">
              {/* {console.log(dataServices.services[temsIndex]?.terms)}
              {console.log(temsIndex)} */}
              <h3 className="text-center mt-4 font-semibold text-xl mb-6">
                Formulir Berkas <br /> {dataServices.services[temsIndex]?.title}
              </h3>

              <p className="mb-2">Berkas Yang Anda Miliki Saat Ini ?</p>

              {dataServices.services[temsIndex]?.terms?.map((term, i) => (
                <div className="flex items-center" key={i}>
                  <input
                    onClick={handleCheckboxChange}
                    type="checkbox"
                    name={i}
                    id={i}
                    value={term}
                    className="mx-4"
                  />
                  <p>{term}</p>
                </div>
              ))}

              <input
                type="text"
                className="border border-gray-400 w-full mt-4 px-4 py-1 rounded-md focus:outline-none focus:ring focus:ring-blue-300 focus:border-blue-200 mb-6"
                placeholder="Nomor Telpon"
              />

              <div className="flex ">
                <button
                  className="bg-gray-200 hover:bg-gray-300 ring-gray-300 w-full py-2 rounded-md font-semibold focus:outline-none focus:ring mr-2"
                  onClick={() => setModal(!modal)}
                >
                  Batal
                </button>

                <a
                  href={`https://api.whatsapp.com/send?phone=6282190107559&text=${text1}`}
                  className="bg-primary hover:bg-yellow-400 ring-yellow-200 w-full py-2 rounded-md font-semibold focus:outline-none focus:ring mb-0 text-center ml-2"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Kirim
                </a>
              </div>

              <button
                onClick={() => showModal(!modal)}
                className="h-6 w-6 absolute right-4 top-4"
              >
                <svg
                  className="text-black"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
        )}
      </section>
    </Layout>
  );
}
