import React from "react";
// import Fade from "react-reveal/Fade";

import Card from "../components/Card";

import feature1 from "../assets/image/feature-11.png";
import feature2 from "../assets/image/feature-22.png";
import feature3 from "../assets/image/feature-33.png";
import feature4 from "../assets/image/feature-44.png";
import feature5 from "../assets/image/feature-55.png";

export default function Layanan(props) {
  const dataFeatures = {
    id: "asdasdwq123123",
    features: [
      {
        id: "asdasdwq123123",
        title: "Konsultasi Hukum",
        description:
          "Pilih Pengacara/Notaris sesuai permasalahan Hukum/Legalmu",
        imgUrl: feature4,
        link: "/konsultasi-hukum",
      },
      {
        id: "xcvx213",
        title: "Pendirian Badan Usaha",
        description:
          "Layanan Pendirian PT, CV, FIRMA, Perusahaan Perorangan, serta Yayasan",
        imgUrl: feature3,
        link: "/pendirian-badan-usaha",
      },
      {
        id: "asd123",
        title: "Keperluan Hukum Perusahaan",
        description:
          "Berbagai Layanan Kebutuhan hukum/legal bagi perusahaan atau badan usahamu",
        imgUrl: feature1,
        link: "/keperluan-hukum-perusahaan",
      },
      {
        id: "qweq123",
        title: "Keperluan Hukum Perorangan",
        description: "Selesaikan Berbagai Kebutuhan Hukum/legalmu ",
        imgUrl: feature2,
        link: "/keperluan-hukum-perorangan",
      },
      {
        id: "asdasdwq123123",
        title: "Donasi Hukum",
        description: "Program bantuan hukum untuk sesama",
        imgUrl: feature5,
        link: "/donasi-hukum",
      },
    ],
  };

  return (
    <section
      className="bg-gray-100 py-28 xl:flex xl:items-center"
      id="layanan"
      ref={props.refLayanan}
    >
      <div className="container mx-auto px-4 xl:px-0">
        <div className="flex flex-start mb-4">
          {/* START: Title Section */}
          <div className="w-full text-center">
            <h2 className="text-4xl font-semibold mb-2">Layanan</h2>
            <p className="text-gray-500">Pilih Kebutuhan Hukum/legalmu</p>
          </div>
          {/* END: Title Section */}
        </div>

        <div className="flex flex-wrap justify-center">
          {dataFeatures.features.map((feature, i) => {
            return (
              <div className="w-1/2 px-2 xl:px-0 xl:w-1/5  mt-6" key={i}>
                {/* <Fade key={feature.id} bottom delay={300 * i}> */}
                <Card
                  image={feature.imgUrl}
                  title={feature.title}
                  desc={feature.description}
                  linkTo={feature.link}
                />
                {/* </Fade> */}
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}
