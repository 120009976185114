import React, { useEffect } from "react";
import Layout from "../../components/Layout";

export default function DonasiHukum(props) {
  const handleClick = () => {
    const url = `https://api.whatsapp.com/send?phone=6282190107559&text=Halo Hukum Digital,%0aSaya Ingin Melalukan Donasi Hukum, Boleh Di Tindak Lanjuti%0aTerima Kasih.`;
    window.open(url, "_blank");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout {...props} isNavBlack>
      <div className="h-screen flex items-center justify-center">
        <div className="p-4 xl:p-24 flex flex-col justify-center items-center">
          <h1 className="text-3xl font-bold mb-8">Donasi Hukum</h1>
          <p className="text-lg font-semibold text-center mb-8">
            Donasi anda, akan kami gunakan untuk menjalankan berbagai program
            sosial dibidang hukum, Mulai dari :
          </p>

          <div className="w-full md:w-1/2 mb-8">
            <div className="flex">
              <p className="mr-4 font-semibold w-3 h-3">1</p>
              <p className="">
                Membuat program IUMK gratis, bagi pelaku usaha Mikro.
              </p>
            </div>
            <div className="flex">
              <p className="mr-4 font-semibold w-3 h-3">2</p>
              <p className="">
                Program Edukasi Hukum ke masyarakat pedesaan, dan anak jalanan.
              </p>
            </div>
            <div className="flex">
              <p className="mr-4 font-semibold w-3 h-3">3</p>
              <p className="">
                Program pelatihan hukum, (pelatihan untuk masyarakat premanisme)
              </p>
            </div>
            <div className="flex">
              <p className="mr-4 font-semibold w-3 h-3">4</p>
              <p className="">
                Program bantuan pendampingan hukum dan konsultasi gratis, bagi
                masyarakat kurang mampu, untuk mendapatkan keadilan hukum.
              </p>
            </div>
          </div>

          {/* <div className=""> */}
          <button
            className="bg-primary hover:bg-yellow-400 px-6 py-2.5 rounded-sm text-sm  md:text-base font-semibold focus:outline-none focus:ring focus:ring-yellow-200"
            onClick={() => handleClick()}
          >
            Hubungi Kami
          </button>
          {/* </div> */}
        </div>
      </div>
    </Layout>
  );
}
