import React from "react";
import Card from "../components/Card";
import Coba from "../assets/image/testi-coba.png";

export default function ListPengacara(props) {
  const { data, isCategory } = props;

  const handleClickTelp = (title) => {
    const url = `https://api.whatsapp.com/send?phone=6282190107559&text=Halo Admin,%0aSaya Ingin Konsultasi Dengan Bapak ${title} Via Telpon, Bisa Tolong Di Aturkan Jadwalnya.%0aTerima Kasih.`;
    window.open(url, "_blank");
  };

  const handleClickChat = (title) => {
    const url = `https://api.whatsapp.com/send?phone=6282190107559&text=Halo Admin,%0aSaya Ingin Konsultasi Dengan Bapak ${title} Via Chat, Bisa Tolong Di Aturkan Jadwalnya.%0aTerima Kasih.`;
    window.open(url, "_blank");
  };

  const handleClickCategory = (route) => {
    props.history.push(route);
  };

  return (
    <>
      <div
        className={`flex flex-wrap w-full ${
          isCategory ? "" : "h-screen80 overflow-y-scroll"
        }`}
      >
        {data.map((item, i) => (
          <Card
            key={i}
            isKonsultasi
            data={item}
            funcTelp={() => handleClickTelp(item.nama)}
            funcChat={() => handleClickChat(item.nama)}
          />
        ))}
        {/* {data.listPengacara.map((item, i) => (
          <Card
            key={i}
            isKonsultasi
            image={Coba}
            nama={item.name}
            profesi={item.profesi}
            funcTelp={() => handleClickTelp(item.name)}
            funcChat={() => handleClickChat(item.name)}
          />
        ))} */}
      </div>

      {/* {isCategory && (
        <>
          <hr className="bg-gray-200 h-1 rounded-full mt-4" />

          <div className="mt-2">
            <p className="font-semibold text-center mb-1">
              Pilih Kategori Pengacara
            </p>
            {data.categoryHukum.map((category, i) => (
              <button
                key={i}
                className="bg-primary hover:bg-yellow-400 px-5 py-1 w-auto mt-3 text-sm font-semibold focus:outline-none focus:ring focus:ring-yellow-200 rounded-full mr-3"
                onClick={() => handleClickCategory(category.link)}
              >
                {category.title}
              </button>
            ))}
          </div>
        </>
      )} */}
    </>
  );
}
