import axios from "axios";
import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import pengacaras from "../../json/pengacara.json";
import ListPengacara from "../../parts/ListPengacara";

export default function KonsultasiHukum(props) {
  const [pilihan, setPilihan] = useState(true);
  const [pengacara, setPengacara] = useState(true);
  const [notaris, setNotaris] = useState(false);
  const [chooseCategory, setChooseCategory] = useState(false);

  const [listPengacara, setListPengacara] = useState([]);

  useEffect(() => {
    // const cekRoute = () => {
    //   const path = props.history.location.pathname;
    //   let checkPath = path.includes("category");

    //   if (checkPath) {
    //     setChooseCategory(true);
    //     setPengacara(false);
    //   }
    // };

    // cekRoute();

    axios
      .get("https://api.hukumdigital.com/master/pengacara", {
        headers: {
          "Access-Control-Allow-Origin": true,
        },
      })
      .then((res) => {
        setListPengacara(res.data.result);
        console.log(res.data.result);
      })
      .catch((err) => console.log(err.response));

    window.scrollTo(0, 0);
  }, [props.history.location.pathname]);

  return (
    <Layout {...props} isNavBlack>
      <div className="flex w-full justify-center overflow-hidden ">
        <div className=" lg:px-8 px-4 pt-24 w-full">
          <div className="flex flex-wrap  md:-mx-4">
            <div className="w-full md:w-1/2 md:px-4">
              <div className="">
                <div className="mb-8 md:h-screen90 md:mb-0 md:px-4">
                  <h5 className="text-center font-semibold mb-5">
                    Chat dan telpon dengan Konsultan serta Praktisi Hukum Terpercaya
                  </h5>
                  {/* <p className="text-gray-500 text-center mb-8">
                    *Syarat dan ketentuan berlaku untuk chat dan telpon gratis
                    dengan pengacara
                  </p> */}

                  <p className="font-semibold mb-2">
                    Alur untuk chat dan telpon dengan Konsultan serta Praktisi Hukum
                  </p>

                  <div className="flex px-4">
                    <p className="mr-4">1.</p>
                    <p>
                      Di arahkan ke admin untuk atur jadwal chat atau nelpon
                      dengan Konsultan serta Praktisi Hukum
                    </p>
                  </div>

                  <div className="flex px-4">
                    <p className="mr-4">2.</p>
                    <p>Membayar biaya chat atau nelpon</p>
                  </div>

                  <div className="flex px-4">
                    <p className="mr-4">3.</p>
                    <p>Admin menghubungkan anda dengan Konsultan serta Praktisi Hukum</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full md:w-1/2 md:-ml-4 flex mb-8">
              {/* {pilihan && (
                <div className="flex flex-col items-center">
                  <p className="mb-6 font-semibold">
                    Silahkan pilih anda mau konsultasi dengan siapa ?
                  </p>
                  <div className="">
                    <button
                      className="bg-primary hover:bg-yellow-400 px-6 py-2.5 rounded-sm text-sm  md:text-base font-semibold focus:outline-none focus:ring focus:ring-yellow-200 mr-4"
                      onClick={() => {
                        setPengacara(true);
                        setPilihan(false);
                      }}
                    >
                      Pengacara
                    </button>

                    <button
                      className="bg-primary hover:bg-yellow-400 px-6 py-2.5 rounded-sm text-sm  md:text-base font-semibold focus:outline-none focus:ring focus:ring-yellow-200"
                      onClick={() => {
                        setNotaris(true);
                        setPilihan(false);
                      }}
                    >
                      Notaris
                    </button>
                  </div>
                </div>
              )} */}

              {pengacara && (
                <div className="md:px-4 w-full">
                  {/* <button
                    className="flex items-center mb-4 font-semibold focus:outline-none"
                    onClick={() => {
                      props.history.push("/konsultasi-hukum");
                      setPengacara(false);
                      setPilihan(true);
                    }}
                  >
                    <svg
                      className="w-5 mr-2"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M15 19l-7-7 7-7"
                      />
                    </svg>
                    Kembali
                  </button> */}

                  {/* <ListPengacara
                    data={pengacaras.konsultasiHukum.pengacara}
                    isCategory
                    {...props}
                  /> */}
                  <ListPengacara data={listPengacara} isCategory {...props} />
                </div>
              )}

              {/* {notaris && (
                <div className="-mx-4 px-4">
                  <button
                    className="flex items-center mb-4 font-semibold focus:outline-none"
                    onClick={() => {
                      setNotaris(false);
                      setPilihan(true);
                    }}
                  >
                    <svg
                      className="w-5 mr-2"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M15 19l-7-7 7-7"
                      />
                    </svg>
                    Kembali
                  </button>

                  <ListPengacara
                    data={pengacaras.konsultasiHukum.Notaris}
                    {...props}
                  />
                </div>
              )} */}

              {/* {chooseCategory && (
                <div className="-mx-4 px-4">
                  <button
                    className="flex items-center mb-4 font-semibold focus:outline-none"
                    onClick={() => {
                      props.history.push("/konsultasi-hukum");
                      setChooseCategory(false);
                      setPilihan(false);
                      setPengacara(true);
                    }}
                  >
                    <svg
                      className="w-5 mr-2"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M15 19l-7-7 7-7"
                      />
                    </svg>
                    Kembali
                  </button>

                  <ListPengacara
                    data={pengacaras.konsultasiHukum.pengacara}
                    {...props}
                  />
                </div>
              )} */}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
