import React from "react";
import Fade from "react-reveal/Fade";

import hero from "../assets/image/hero-bg.jpg";

export default function Hero(props) {
  const handleClick = () => {
    const url = `https://api.whatsapp.com/send?phone=6282190107559&text=Halo Hukum Digital,%0aSaya Ingin Bertanya Soal Paket Hukum Yang Ada Disini%0aTerim Kasih.`;
    window.open(url, "_blank");
  };

  return (
    <section
      className="bg-cover bg-center xl:bg-top relative h-screen "
      style={{ backgroundImage: "url(" + hero + ")", height: "100vh" }}
      ref={props.refHero}
    >
      <div className="container mx-auto relative z-10 top-16">
        <div className="flex flex-wrap justify-center items-center h-screen90">
          <div className="w-full md:w-4/5 lg:w-2/3 xl:w-1/2 px-4 flex flex-col items-center ">
            <Fade duration={3000}>
              <div className="text-center text-white mb-8">
                <p className="mb-1">Kami Siap Membantu Anda !</p>
                <h2 className="text-4xl font-semibold mb-4">
                  Selamat Datang Di{" "}
                  <span className="font-bold">
                    <span className="text-primary">Hukum </span>Digital
                  </span>
                </h2>
                <p>
                  Platform Legal Service dengan system layanan satu pintu untuk
                  berbagai Kebutuhan Hukum/legal anda.
                </p>
              </div>
              <button
                className="bg-primary hover:bg-yellow-400 px-6 py-2.5 rounded-sm text-sm  md:text-base font-semibold focus:outline-none focus:ring focus:ring-yellow-200"
                onClick={() => handleClick()}
              >
                Hubungi Kami
              </button>
            </Fade>
          </div>
        </div>
      </div>
      <div className="overlay flex justify-center items-end">
        <div className="text-white py-4 w-full bg-gradient-to-t hover:from-gray-800 hover:bg-opacity-30 z-10 cursor-pointer flex justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="w-7 animate-bounce"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M19 13l-7 7-7-7m14-8l-7 7-7-7"
            />
          </svg>
        </div>
      </div>
    </section>
  );
}
