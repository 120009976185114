import React, { useEffect } from "react";
import Layout from "../../components/Layout";
import Hero from "../../parts/Hero";
import Iklan from "../../parts/Iklan";
import Layanan from "../../parts/Layanan";
import TentangKami from "../../parts/TentangKami";
import Testimoni from "../../parts/Testimoni";

export default function Home(props) {
  let scrollTo = {
    hero: React.createRef(),
    layanan: React.createRef(),
    tentang: React.createRef(),
    testimoni: React.createRef(),
    kontak: React.createRef(), //buat di halaman pendirian
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);

  return (
    <Layout {...props} refHeader={scrollTo}>
      <Hero refHero={scrollTo.hero} />
      <Layanan refLayanan={scrollTo.layanan} />
      <Iklan />
      <TentangKami refTentang={scrollTo.tentang} />
      <Testimoni refTestimoni={scrollTo.testimoni} />
    </Layout>
  );
}
