import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from "../Home";
import PendirianBadanUsaha from "../PendirianBadanUsaha";
import KeperluanHukumPerusahaan from "../KeperluanHukumPerusahaan";
import KeperluanHukumPerorangan from "../KeperluanHukumPerorangan";
import KonsultasiHukum from "../KonsultasiHukum";
import DonasiHukum from "../DonasiHukum";

function App() {
  return (
    <div className="App">
      <Router>
        <Route path="/" exact component={Home} />
        <Route path="/pendirian-badan-usaha" component={PendirianBadanUsaha} />
        <Route
          path="/keperluan-hukum-perusahaan"
          component={KeperluanHukumPerusahaan}
        />
        <Route
          path="/keperluan-hukum-perorangan"
          component={KeperluanHukumPerorangan}
        />
        <Route path="/konsultasi-hukum" component={KonsultasiHukum} />
        <Route path="/donasi-hukum" component={DonasiHukum} />
        {/* <Route
          path={["/konsultasi-hukum", "/konsultasi-hukum/category/perbankan"]}
          component={KonsultasiHukum}
        /> */}
      </Router>
    </div>
  );
}

export default App;
