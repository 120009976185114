import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/logo/logo.png";

function Navbar(props) {
  function showLayanan(refScrollTo) {
    props.location.pathname !== "/"
      ? props.history.push("/")
      : window.scrollTo({
          top: refScrollTo - 50,
          behavior: "smooth",
        });
  }

  const handleClickIcon = (e) => {
    const listDropdown = document.getElementsByTagName("ul")[0];
    const arrowIcon = e.target;

    if (listDropdown.className.indexOf("h-0") > -1) {
      arrowIcon.classList.add("rotate-180");
      listDropdown.classList.add(
        "opacity-100",
        "lg:bg-black",
        "lg:h-auto",
        "p-4"
      );
      listDropdown.classList.remove("h-0", "invisible", "opacity-0");
    } else {
      arrowIcon.classList.remove("rotate-180");
      listDropdown.classList.remove(
        "opacity-100",
        "lg:bg-black",
        "lg:h-auto",
        "p-4"
      );
      listDropdown.classList.add("h-0", "invisible", "opacity-0");
    }
  };

  const isNavLinkActive = (path) => {
    return props.location.pathname === path ? " font-bold" : "";
  };

  const [isMobile, setIsMobile] = useState(false);

  return (
    <div
      className={`w-full py-4 px-4 fixed bg-black ${
        props.isBlack ? "" : "lg:bg-transparent"
      } z-50 top-0 right-0 left-0 transition duration-500 text-white`}
      id="navbar"
    >
      <div className="bg-transparent container mx-auto ">
        <div className="flex flex-col lg:flex-row">
          <div className="flex items-center justify-between ">
            {/* START: Logo */}
            <div
              className="w-full lg:w-60 flex items-center cursor-pointer"
              onClick={(e) =>
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                })
              }
            >
              <div className="w-8 mr-4">
                <img
                  src={Logo}
                  alt="Hukum Digital"
                  className="object-cover object-center"
                />
              </div>

              <Link to="/" className="font-bold uppercase leading-4">
                Hukum Digital <br />
                <span className="text-xs font-bold">Digital Law Service</span>
              </Link>
            </div>
            {/* END: Logo */}

            {/* START: Hamburger Button */}
            <div>
              <button
                onClick={() => setIsMobile(!isMobile)}
                className="focus:outline-none text-white block lg:hidden"
              >
                <svg
                  className="w-8 h-8 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    className={!isMobile ? "block" : "hidden"}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16m-7 6h7"
                  />
                  <path
                    className={isMobile ? "block" : "hidden"}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            {/* END: Hamburger Button */}
          </div>

          {/* START: NavLink */}
          <div
            className={`${
              isMobile ? "block" : "hidden"
            } lg:flex flex-col lg:flex-row w-full lg:items-center justify-end lg:relative`}
          >
            <div className="flex flex-col mt-3 lg:mt-0 lg:flex-row text-base lg:relative">
              <Link
                to="/"
                className={`block py-1 lg:py-0 lg:px-4  ${isNavLinkActive(
                  "/"
                )}`}
                onClick={() =>
                  showLayanan(props.refHeader?.hero.current.offsetTop)
                }
              >
                Home
              </Link>
              <div className="flex items-center justify-between">
                <Link
                  to="/"
                  onClick={() =>
                    showLayanan(props.refHeader?.layanan.current.offsetTop)
                  }
                  className={`block py-1 lg:py-0 lg:pl-4 lg:pr-2 ${isNavLinkActive(
                    "/layanan"
                  )}`}
                  id="btnLayanan"
                >
                  Layanan
                </Link>

                <svg
                  className="cursor-pointer transform transition duration-200"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  onClick={handleClickIcon}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </div>

              <ul className="h-0 invisible overflow-hidden lg:absolute top-12 left-24">
                <li>
                  <Link
                    to="/pendirian-badan-usaha"
                    className="hover:underline py-1 block"
                  >
                    Pendirian Badan Usaha
                  </Link>
                </li>
                <li>
                  <a href="/" className="hover:underline py-1 block">
                    Keperluan Hukum Perusahaan
                  </a>
                </li>
                <li>
                  <a href="/" className="hover:underline py-1 block">
                    Keperluan Hukum Perorangan
                  </a>
                </li>
                <li>
                  <a href="/" className="hover:underline py-1 block">
                    Konsultasi Hukum
                  </a>
                </li>
              </ul>

              <Link
                to="/"
                onClick={() =>
                  showLayanan(props.refHeader?.tentang.current.offsetTop)
                }
                className={`block py-1 lg:py-0 lg:px-4 ${isNavLinkActive(
                  "/tentang"
                )}`}
              >
                Tentang
              </Link>

              <Link
                to="/"
                onClick={() =>
                  showLayanan(props.refHeader?.testimoni.current.offsetTop)
                }
                className={`block py-1 lg:py-0 lg:px-4 ${isNavLinkActive(
                  "/testimoni"
                )}`}
              >
                Testimoni
              </Link>

              <Link
                to="/"
                // onClick={() =>
                //   showLayanan(props.refHeader.kontak.current.offsetTop)
                // }
                // className={`block py-1 lg:py-0 lg:px-4 ${isNavLinkActive(
                //   "/kontak"
                // )}`}
              >
                Kontak
              </Link>
            </div>
          </div>
          {/* END: NavLink */}
        </div>
      </div>
    </div>
  );
}

export default Navbar;
