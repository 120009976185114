import React from "react";
import Navbar from "../../parts/Navbar";
import Footer from "../../parts/Footer";

export default function Layout(props) {
  document.title = props.title || "Hukum Digital";

  return (
    <div className="w-full bg-white relative z-20 ">
      <Navbar {...props} isBlack={props.isNavBlack ?? ""} />
      {props.children}

      {props.isNoFooter ? null : <Footer />}
      {/* <Footer /> */}
      {/* <Footer refKontak={props.refHeader.kontak} /> */}
    </div>
  );
}
