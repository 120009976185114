import React from "react";
import Fade from "react-reveal/Fade";

import Card from "../components/Card";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import testi1 from "../assets/image/testi-1.jpeg";
import testi2 from "../assets/image/testi-2.jpeg";
import testi3 from "../assets/image/testi-3.jpeg";
import testi4 from "../assets/image/testi-4.jpeg";
import testi5 from "../assets/image/testi-5.jpeg";
// import testiCoba from "../assets/image/testi-coba.png";

export default function Testimoni(props) {
  const dataTestimoni = {
    id: "asdasd123",
    items: [
      {
        id: "cassad12312",
        nama: "PT. Mall Sampah Indonesia",
        // profesi: "Desin Grafis",
        testimoni:
          "Hukum digital telah hadir dengan suatu konsep terbaru, sehingga saya dapat terbantu dalam setiap keperluan hukum, serasa memiliki konsultan hukum pribadi bedanya ini lewat digital",
        imgUrl: testi1,
      },
      {
        id: "cvaqwdd12",
        nama: "PT. Flash Informatika",
        // profesi: "Video Editor",
        testimoni:
          "terima kasih hukum digital telah membantu kami dalam pendaftaran merek, proses sangat cepat dan kakak-kakaknya baik baik",
        imgUrl: testi2,
      },
      {
        id: "cvaqwdd12",
        nama: "PT. Bin Djunaid Abbas",
        // profesi: "Video Editor",
        testimoni:
          "Terima kasih, hukum digital sangat membantu kami dalam berbagai kebutuhan legalitas perusahaan kami",
        imgUrl: testi3,
      },
      {
        id: "cvaqwdd12",
        nama: "CV. Kila Express",
        // profesi: "Video Editor",
        testimoni:
          "Hukum digital sangat membantu saya tanpa harus mengeluarkan tenaga, hanya lewat smartphone keperluan legalitas saya terselesaikan",
        imgUrl: testi4,
      },
      {
        id: "cvaqwdd12",
        nama: "HelPer indonesia",
        // profesi: "Video Editor",
        testimoni:
          "Terima kasih kepada hukum digital, dengan sistem yang telah ditawarkan dapat mempermudah kami dalam urusan legalitas perusahaan kami",
        imgUrl: testi5,
      },
    ],
  };

  return (
    <section className="pt-10 pb-36" id="testimoni" ref={props.refTestimoni}>
      <Fade>
        <div className="container mx-auto px-4">
          <div className="flex flex-start mb-6">
            <div className="w-full text-center">
              {/* <h3 className="text-2xl capitalize font-semibold mb-2">
                Testimoni
              </h3>
              <p className="capitalize">
                Apa yang meraka katakan tentang kami perusahaan kami ?
              </p> */}
            </div>
          </div>

          <OwlCarousel
            className="owl-theme"
            loop
            margin={10}
            items={1}
            autoplay
            smartSpeed={1000}
            autoplayTimeout={5000}
            center
          >
            {dataTestimoni.items.map((item, i) => (
              <Card
                key={item.id}
                isTestimoni
                nama={item.nama}
                profesi={item.profesi}
                testimoni={item.testimoni}
                image={item.imgUrl}
                // image={testiCoba}
              />
            ))}
          </OwlCarousel>
        </div>
      </Fade>
    </section>
  );
}
