import React from "react";

import Layout from "../../components/Layout";

import dataKeperluanHukumPerusahaan from "../../json/keperluan-hukum-perusahaan.json";

export default function KeperluanHukumPerusahaan(props) {
  const refSection = [
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ];

  const handleOnClickListSidebar = (ref, id) => {
    const parentList = document.querySelector("#parentList");
    const sideContent = document.getElementById("sideContent");
    const dataContent = document.getElementById(id).offsetTop;

    parentList.scrollTo({
      left: ref.current.offsetLeft - 16,
      behavior: "smooth",
    });

    window.innerWidth < 768
      ? window.scrollTo({
          top: dataContent - 150,
        })
      : sideContent.scrollTo({
          top: dataContent - 80,
        });
  };

  const handleHubungiKami = (e, title) => {
    const url = `https://api.whatsapp.com/send?phone=6282190107559&text=Halo Admin,%0aSaya Ingin Memesan Paket ${title}`;
    window.open(url, "_blank");
  };

  return (
    <Layout {...props} isNavBlack isNoFooter>
      <div className="md:flex">
        {/* START : Sidebar */}
        <section className="bg-white side-bar w-full md:relative fixed z-10 md:w-2/3 lg:w-1/2 xl:w-1/3 ">
          <div className="container mx-auto pt-16 overflow-hidden">
            <div
              className="md:side-bar md:block overflow-x-scroll md:overflow-x-hidden md:overflow-y-scroll whitespace-nowrap py-4 mx-4 md:side-bar md:h-screen90"
              id="parentList"
            >
              <ul className="flex md:inline-block">
                {dataKeperluanHukumPerusahaan.data.map((data) => (
                  <li
                    className="px-4 py-2 rounded cursor-pointer"
                    key={data.id}
                    id={data.id}
                    ref={refSection[data.id - 1]}
                    onClick={() =>
                      handleOnClickListSidebar(
                        refSection[data.id - 1],
                        `${data.title}${data.id}`
                      )
                    }
                  >
                    {data.title}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>
        {/* END : Sidebar */}

        {/* START : Content */}
        <section
          className="content flex-initial md:flex-initial md:pt-16 overflow-y-scroll md:h-screen scroll"
          id="sideContent"
        >
          <div className="container mx-auto px-4 md:px-8 mt-36 md:mt-8">
            {dataKeperluanHukumPerusahaan.data.map((data) => (
              <div
                className="akta-perubahaan border py-4 px-6 my-8 md:px-8 lg:mx-6"
                key={data.id}
                id={`${data.title}${data.id}`}
              >
                <h1 className="text-3xl font-semibold mb-3">{data.title}</h1>
                <p className="leading-loose">{data.content}</p>
                <button
                  className="px-4 py-2 bg-primary rounded font-semibold mt-3 hover:bg-yellow-400"
                  onClick={(e) => handleHubungiKami(e, data.title)}
                >
                  Hubungi Kami
                </button>
                {/* <a
                  href={`https://api.whatsapp.com/send?phone=6282190107559&text=${}`}
                  className="bg-primary hover:bg-yellow-400 ring-yellow-200 w-full py-2 rounded-md font-semibold focus:outline-none focus:ring mb-0 text-center ml-2"
                  target="_blank"
                >
                  Kirim
                </a> */}
              </div>
            ))}
          </div>
        </section>
        {/* END : Content */}
      </div>
    </Layout>
  );
}
