import React from "react";
import Fade from "react-reveal/Fade";

import law from "../assets/image/lawyer.png";

export default function TentangKami(props) {
  return (
    <section
      className="py-14 bg-gray-50"
      id="tentang-kami"
      ref={props.refTentang}
    >
      <Fade>
        <div className="container mx-auto px-8 xl:px-4">
          <div className="flex flex-start mb-4">
            <div className="w-full flex flex-col justify-center">
              <h2 className="font-semibold">Tentang Kami</h2>
              <h3 className="uppercase text-3xl font-bold mb-4">
                <span className="text-primary">HUKUM</span> DIGITAL
              </h3>
              <p className="leading-relaxed capitalize mb-4">
                Merupakan platform legal digital yang menyediakan berbagai
                layanan hukum dengan menerapkan sistem one door digital service
                for all legal needs, mulai dari konsultasi hukum dengan para
                praktisi hukum (Pengacara dan Notaris), pendirian badan usaha,
                keperluan legal/hukum perusahaan, hingga keperluan hukum bagi
                perorangan.
              </p>
              {/* <table className="table-fixed capitalize border-separate">
                <tbody>
                  <tr>
                    <td className="w-1/12 align-top">1</td>
                    <td className="w-full">
                      legalitas untuk membantu para pengusaha dalam melegalkan
                      usahanya.
                    </td>
                  </tr>

                  <tr>
                    <td className="w-1/12 align-top">2</td>
                    <td className="w-full">
                      Butuh Pengacara untuk membantu masyarakat dalam mencari
                      penasehat hukum.
                    </td>
                  </tr>

                  <tr>
                    <td className="w-1/12 align-top">3</td>
                    <td className="w-full">
                      Edukasi Hukum sebagai layanan informasi hukum kepada
                      masyarakat luas serta membimbing mahasiswa dalam
                      menentukan sikap pemilihan kategori profesi dibidang
                      hukum.
                    </td>
                  </tr>
                </tbody>
              </table> */}
            </div>
            <div className="hidden w-full md:flex px-12 items-center justify-center">
              <img src={law} alt="" />
            </div>
          </div>
        </div>
      </Fade>
    </section>
  );
}
