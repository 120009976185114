import React from "react";

import Fade from "react-reveal/Fade";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import hero1 from "../assets/image/hero-1.jpeg";
import hero2 from "../assets/image/hero-2.jpeg";
import hero3 from "../assets/image/hero-3.png";
import hero4 from "../assets/image/hero-4.jpeg";

export default function Iklan() {
  let responsive = {
    0: {
      items: 1,
    },
    768: {
      items: 2,
    },
  };

  return (
    <Fade>
      <div className="container mx-auto overflow-hidden pt-16">
        <div className="w-full flex flex-col items-center md:h-screen90 px-4">
          <div className="text-center mb-12 w-full md:w-1/2">
            <h3 className="text-4xl font-semibold mb-2">Penawaran Menarik</h3>
            <p className="capitalize">
              Beberapa Penawaran Terbaik untuk keperluan hukum/legalmu
            </p>
            {/* <hr className="border-t-8 w-1/12 mt-4 mx-auto rounded-full border-primary" /> */}
          </div>

          <OwlCarousel
            className="owl-theme"
            loop
            margin={30}
            autoplay
            dots
            autoplayTimeout={5000}
            center
            responsive={responsive}
          >
            <div className="item w-full ">
              <img src={hero1} alt="" className="iklan" />
            </div>
            <div className="item ">
              <img src={hero2} alt="" className="iklan" />
            </div>
            <div className="item ">
              <img src={hero3} alt="" className="iklan" />
            </div>
            <div className="item ">
              <img src={hero4} alt="" className="iklan" />
            </div>
          </OwlCarousel>
        </div>
      </div>
    </Fade>
  );
}
